import axios from "axios";
import socket from "utils/socket";
import { AuthenticationResult } from "@azure/msal-common";
import { PublicClientApplication } from "@azure/msal-browser";
import history from "utils/history";
import { unauthorizedPath } from "utils/constants";
declare global {
  interface Window {
    ESG: any;
  }
}
const APIS = window.ESG?.ENV?.GEOS;
const currGeoCode = window.ESG?.ENV?.CURRENT_GEO;
let accessToken = "";

// Define the msal object for authentication
const scopes = [window.ESG?.ENV?.AD_CLIENT + "/.default"];
const port = window.location.port ? `:${window.location.port}` : "";
const authority = `${window.ESG?.ENV?.AD_INSTANCE}/${window.ESG?.ENV?.AD_TENANT}/`;
const redirectUri = `${window.location.protocol}//${window.location.hostname}${port}`;

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: window.ESG?.ENV?.AD_CLIENT,
    authority: authority,
    redirectUri: redirectUri,
    knownAuthorities: [authority],
    postLogoutRedirectUri: `https://www.deloitte.com/`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
});

// Initialize Current GEO API
var apiIndex = currGeoCode
  ? APIS.findIndex((currAPI: any) => currAPI.geoCode === currGeoCode)
  : 0;
apiIndex = apiIndex > -1 ? apiIndex : 0;

const currentAPI = APIS[apiIndex];
const api = axios.create({
  baseURL: currentAPI.API_BASE_URL,
});
api.defaults.headers.common["X-CNTR-CTX"] = "US";
api.defaults.headers.common["X-MF-CTX"] = "US";
api.defaults.headers.common["X-LANG-CTX"] = "en-US";
socket.setRegion(currentAPI.HUB_BASE_URL, currentAPI.GEO_CODE, "US");

// MSAL Authentication
export const authenticate = (): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    const tokenResponse = await handleLoginResponse();
    resolve(tokenResponse ? true : false);
  });
};

export function userLogOut() {
  msalInstance.logoutRedirect({
    account: msalInstance.getAllAccounts()[0],
  });
}

const handleLoginResponse = async (): Promise<AuthenticationResult | null> => {
  let tokenResponse = await msalInstance.handleRedirectPromise();
  let account;

  if (tokenResponse) {
    account = tokenResponse.account;
  } else {
    account = msalInstance.getAllAccounts()[0];
  }

  if (account) {
    try {
      tokenResponse = await msalInstance.acquireTokenSilent({
        account,
        scopes,
      });
    } catch (err) {
      await msalInstance.acquireTokenRedirect({ scopes });
    }
  } else {
    await msalInstance.loginRedirect();
  }

  return tokenResponse;
};

// Interceptor for adding the token to each request
api.interceptors.request.use(
  async (config) => {
    if (accessToken === "") {
      const tokenResponse = await handleLoginResponse();
      accessToken = tokenResponse ? tokenResponse.accessToken : "";
      socket.setAccessToken(accessToken);
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.sameSite = "None";

    return config;
  },
  (err) => Promise.reject(err)
);

// Interceptor for catching 401s and handling token refresh
api.interceptors.response.use(undefined, (err) => {
  const { response } = err;

  if (
    response &&
    response.status === 401 &&
    response.config &&
    !response.config.__isRetryRequest
  ) {
    response.config.__isRetryRequest = true;

    return new Promise(async (resolve, reject) => {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes,
      });
      if (tokenResponse) {
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${tokenResponse.accessToken}`;
        accessToken = tokenResponse.accessToken;
        err.config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`;
        socket.setAccessToken(tokenResponse.accessToken);
        axios(err.config).then(resolve, reject);
      }
    });
  }

  throw err;
});

// Interceptor for catching 403s
api.interceptors.response.use(undefined, (err) => {
  const { response } = err;

  if (response && response.status === 403) {
    history.push(unauthorizedPath);
  }

  throw err;
});

export default api;
