import Layout from "./layout";
import HomePage from "components/home/home-page";
import { useEffect, useState } from "react";
import { authenticate, msalInstance } from "services/api.service";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import history from "utils/history";
import { ConnectedRouter } from "connected-react-router-for-react18";
import { Route, Switch } from "react-router-dom";
import Logout from "components/logout/logout";
import { logout } from "utils/constants";
import CookieNotice from "../cookie-notice/cookie-notice";
import FormattedMessage from "components/shared/formatted-message/formatted-message";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated)
      authenticate().then(setIsAuthenticated);
  }, [setIsAuthenticated, inProgress, isAuthenticated]);

  return (
    <ConnectedRouter history={history}>
      <MsalProvider instance={msalInstance}>
        <span className="cookielink">
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            <FormattedMessage id="footer.cookies" />
          </button>
        </span>
        {/* HomePage */}
        <Switch>
          <Route exact path={logout}>
            <Layout>
              <Logout />
            </Layout>
          </Route>
          <Route path="/cookie-notice">
            <Layout hideSideMenu={true}>
              <CookieNotice />
            </Layout>
          </Route>
          <Route>
            <Layout hideHeader={true} hideSideMenu={true}>
              <HomePage />
            </Layout>
          </Route>
        </Switch>
      </MsalProvider>
    </ConnectedRouter>
  );
}

export default App;
