import { useEffect } from "react";
import { cookiesToIgnore } from "./constants";

export const isNumeric = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const mod = (n: number, m: number) => ((n % m) + m) % m;

export const useOutsideAlerter = (ref: any, fn: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) fn();
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const expireCookies = () => {
  // Expiring the cookies
  document.cookie.split(";").forEach((c) => {
    // Ignore the cookies related to the cookie notice 
    if (!cookiesToIgnore.some((ignore) => c.includes(ignore)))
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};
