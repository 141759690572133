import TRACKING_ACTIONS from "./actions";

declare global {
  interface Window {
    _satellite: any;
  }
}

// Main satellite function that updates the digital data layer and calls the DCRs
const satelliteTrack = (eventType: string) => {
  // ensure the variable is set
  if (
    window._satellite &&
    window._satellite.track &&
    window.localStorage.getItem("userID")
  ) {
    window._satellite.track(TRACKING_ACTIONS[eventType].dcr);
  } else {
    setTimeout(() => {
      satelliteTrack(eventType);
    }, 100);
  }
};

const track = (args: any) => {
  satelliteTrack(args);
};

export default track;
