import { expireCookies } from "utils/functions";
import api from "./api.service";

export const getCurrentUser = () =>
  api.get(`users/me`).then(({ data }) => data.data);

export const logoutCurrentUser = () =>
  api
    .get(`users/auth/logout`)
    .then(({ data }) => data.data)
    .catch((e) => expireCookies());
export const getEsgAssets = () =>
  api.get(`/applications`).then(({ data }) => data.data);
export const getAppSetting = (appSettingName: string) =>
  api.get(`/appsettings/${appSettingName}`).then(({ data }) => data.data);
export const getMaintenanceMessage = () =>
  api.get(`maintenancemessages/banner`).then(({ data }) => data);
