import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TOAST_OPTIONS } from "utils/constants";
import Icon from "components/shared/icon/icon";
import { removeToastMessage } from "services/commons.service";

type Props = {
  toastList: any[];
};

const Toast = ({ toastList }: Props) => {
  const BLOCK = "toast";
  const [list, setList] = useState(toastList);
  const dispatch = useDispatch();

  useEffect(() => {
    setList([...toastList]);

    // eslint-disable-next-line
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(0);
      }
    }, TOAST_OPTIONS.dismissTime);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, [toastList, list]);

  // Implemented by splicing the listItemIndex and setting the same array in the reducer
  // The resulting array is then again passed to Toast component to show the toast message
  const deleteToast = (listItemIndex: number) => {
    list.splice(listItemIndex, 1);
    dispatch(removeToastMessage(list));
  };

  return (
    <>
      <div className={`${BLOCK}__container ${TOAST_OPTIONS.position}`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`${BLOCK}__notification ${TOAST_OPTIONS.position}`}
          >
            <div>
              <p className={`${BLOCK}__message`}>{toast.description}</p>
            </div>
            <button className={`${BLOCK}__icon`} onClick={() => deleteToast(i)}>
              <Icon name="cross" height={20} />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Toast;
