import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import commons from "services/commons.service";
import {
  connectRouter,
  routerMiddleware,
} from "connected-react-router-for-react18";
import history from "utils/history";

import { AnalyticsMiddleware } from "adobe-analytics/middleware";

const RootReducer = combineReducers({
  commons,
  router: connectRouter(history),
});

const Store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(AnalyticsMiddleware, thunk))
);

export type RootStore = ReturnType<typeof RootReducer>;
export default Store;
