import classNames from "classnames";
import FormattedMessage from "../formatted-message/formatted-message";
import Icon from "../icon/icon";

type Props = {
  id?: string;
  className: string;
  href?: string;
  onClick?: any;
  formattedText?: string;
  text?: string;
  target?: string;
  children?: any;
  disabled?: boolean;
  dataTest?: string;
  iconName?: string;
  iconHeight?: number;
  iconWidth?: number;
  iconAfter?: boolean;
};

const Button = ({
  id,
  className,
  children,
  disabled,
  formattedText,
  href,
  onClick,
  target,
  text,
  dataTest,
  iconName,
  iconHeight = 24,
  iconWidth = 24,
  iconAfter = false,
}: Props) => {
  const BLOCK = "button";

  return (
    <>
      <button
        id={id}
        className={classNames(`${BLOCK} ${className}`, {
          disabled: disabled,
        })}
        disabled={disabled}
        onClick={(e) => onClick(e)}
        data-test={dataTest}
        data-testid={dataTest}
      >
        {iconName && !iconAfter && (
          <Icon name={iconName} height={iconHeight} width={iconWidth} />
        )}
        {text || children}
        {formattedText && <FormattedMessage id={formattedText} />}

        {iconName && iconAfter && (
          <Icon name={iconName} height={iconHeight} width={iconWidth} />
        )}
      </button>
    </>
  );
};

export default Button;
