import { TooltipPosition } from "components/shared/tooltip/tooltip";
import { ReactElement } from "react";
import { BaseModel } from "utils/redux.utils";
export interface CommonsState {
  toastMessages: ToastMessage[];
  toolTip: {
    elementDimensions: any;
    className: string | undefined;
    width: number | undefined;
    executeMouseLeaveEvent: boolean | undefined;
    position: TooltipPosition;
    customPosition: boolean;
    arrowPosition: Position;
    children: JSX.Element;
  };
  currentUser: User;
  loadingUser: boolean;
  esgAssets: EsgAssets;
  termsOfUse: AppSettings;
  loadEsgAssets: boolean;
  activeMaintenanceMessages: activeMaintenanceMessages[];
  cachedActiveMaintenanceMessages: activeMaintenanceMessages[];
}

type AppSettings = {
  [key: string]: string;
};

export class CommonsModel extends BaseModel {
  constructor() {
    super({
      toastMessages: [],
      toolTip: {
        position: null,
        children: null,
        arrowPosition: null,
        customPosition: null,
      },
      currentUser: {},
      termsOfUse: {},
      loadEsgAssets: false,
      esgAssets: {},
      activeMaintenanceMessages: [],
      cachedActiveMaintenanceMessages: [],
    });
  }

  addToastMessage = (toastMessage: any): CommonsState =>
    this.merge({ toastMessages: [...this.state.toastMessages, toastMessage] });

  setShowTermsOfUseFooter = (termsOfUse: string[]): CommonsState =>
    this.merge({ termsOfUse });

  removeToastMessage = (toastMessage: any[]): CommonsState =>
    this.merge({
      toastMessages: toastMessage,
    });

  showToolTip = (toolTip: any): CommonsState => this.merge({ toolTip });

  loadCurrentUser = (currentUser: User): CommonsState => {
    return this.merge({ currentUser });
  };
  setEsgLoading = (loadEsgAssets: boolean): CommonsState =>
    this.merge({ loadEsgAssets });
  setEsgAssets = (esgAssets: EsgAssets): CommonsState =>
    this.merge({ esgAssets });

  setActiveMaintenanceMessages = (
    activeMaintenanceMessages: activeMaintenanceMessages[]
  ): CommonsState => this.merge({ activeMaintenanceMessages });

  setCachedActiveMaintenanceMessages = (
    cachedActiveMaintenanceMessages: activeMaintenanceMessages[]
  ): CommonsState => this.merge({ cachedActiveMaintenanceMessages });
}

export type Position = {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
};

export type ToastMessage = {
  description: ReactElement;
};

export type User = {
  containerCode: string;
  containerName: string;
  displayName: string;
  email: string;
  employeeStatusCode: string;
  externalSystemId: string;
  firstName: string;
  geoCode: string;
  isActive: boolean;
  lastName: string;
  memberFirmCode: string;
  organizationCode: string;
  preferredFullName: string;
  title: string;
  userId: number;
  adminRoles: Role[];
};

export type Role = {
  roleId: number;
  roleName: string;
};

export type EsgAssets = {
  applications: [
    {
      applicationId: number;
      applicationName: string;
      applicationDescription: string;
      applicationCategoryId: number;
      userAccessTypeId: {
        userAccessTypeId: number;
        userAccessTypeName: string;
      };
      applicationUrl: string;
      applicationIcon: string;
      displayOrder: number;
      isVPNRequire: boolean;
    }
  ];
  applicationCategories: [
    {
      applicationCategoryId: number;
      applicationCategoryName: string;
      displayOrder: number;
      applications?: any[];
    }
  ];
  applicationMappings: [
    {
      applicationId: number;
      applicationCategoryId: number;
    }
  ];
};

export type ApplicationCategories = {
  applicationCategoryId: number;
  applicationCategoryName: string;
  applications: [
    {
      applicationId: number;
      applicationName: string;
      description: string;
      userAccessType: { id: number; name: string };
      url: string;
      icon: string;
      displayOrder: 1;
      isVPNRequire: boolean;
    }
  ];
};

export type activeMaintenanceMessages = {
  maintenanceMessageId: number;
  region: Region;
  maintenanceMessageText: string;
  startDate: string;
  endDate: string;
};

export type Region = {
  regionId: number;
  regionName: string;
  isDisabled: boolean;
};
