import React, { Fragment } from "react";
import data from "./messages.json";

type Props = {
  id: string;
  values?: {
    [key: string]: React.ReactNode;
  };
};

const FormattedMessage = ({ id, values }: Props) => {
  const key = id in data ? id : "default";
  let formattedText = (data as any)[key]?.split(/\{(.*?)\}/i);

  if (values) {
    formattedText = formattedText?.map((part: string, index: number) => {
      if (values.hasOwnProperty(part)) {
        return <Fragment key={index}>{values[part]}</Fragment>;
      }
      return part;
    });
  }

  return <React.Fragment key={id}>{formattedText}</React.Fragment>;
};

export default FormattedMessage;
