import Icon from "../shared/icon/icon";
import { useState, useEffect, useRef } from "react";
import Footer from "components/footer/footer";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
//import emptyimg from "images/saved.shortlist-empty.png";
import SearchNotFound from "images/search.not-found.svg";
import { useOutsideAlerter } from "utils/functions";
import history from "../../utils/history";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import {
  esgPortalAccesedTrack,
  esgPortalAppAccesedTrack,
  getEsgAssets,
} from "services/commons.service";
import {
  APPLICATION_CATEGORY,
  APPLICATION_CATEGORY_TYPE,
  logout,
} from "utils/constants";
import { useIsAuthenticated } from "@azure/msal-react";
import classNames from "classnames";
import Parser from "html-react-parser";
import MaintenanceMessageList from "components/maintenance-messages/active-maintenance-message-list";
import useAppInsightsCustomMetric from "appinsights/app-insights-custom-metrics";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const BLOCK = "HomePage";
  const commons = useSelector((state: RootStore) => state.commons);
  const [logoutMenu, setlogoutMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);
  const supportMenuRef = useRef<HTMLDivElement>(null);
  const logoutMenuRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [esgAssets, setEsgAssets] = useState<any>([]);
  const [userInitials, setUserInitials] = useState("");
  const [userName, setUserName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [category, setCategory] = useState<any>("");
  const [tabs, setTabs] = useState<any>([]);
  const currentUser = commons.currentUser;
  let assets = commons.esgAssets;
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const [navBarAssets, setNavBarAssets] = useState<any>([]);
  const [showHeaderMenu, setShowHeaderMenu] = useState<boolean>(false);
  const hamburgerMenuRef = useRef<HTMLDivElement>(null);
  const [marginTop, setMarginTop] = useState<number>(0);
  const commonState = useSelector((state: RootStore) => state.commons);

  const location = useLocation();
  useAppInsightsCustomMetric(location.pathname);

  const getNavBarAssets = (tmpAssets: any) => {
    let tmpCategoryGroups = tmpAssets.applications
      .filter(
        (c: any) =>
          tmpAssets.applicationMappings.filter(
            (m: any) =>
              m.applicationId === c.applicationId &&
              m.applicationCategoryTypeId === APPLICATION_CATEGORY_TYPE.NAV_BAR
          )[0]
      )
      .reduce((acc: any, curr: any) => {
        let currentApplicationMapping = tmpAssets.applicationMappings.filter(
          (m: any) =>
            m.applicationId === curr.applicationId &&
            m.applicationCategoryTypeId === APPLICATION_CATEGORY_TYPE.NAV_BAR
        )[0];
        let currIndex = -1;
        let currentGroup = acc.filter((a: any, i: number) => {
          if (
            a.applicationCategoryId ===
            currentApplicationMapping.applicationCategoryId
          )
            currIndex = i;
          return (
            a.applicationCategoryId ===
            currentApplicationMapping.applicationCategoryId
          );
        })[0];
        if (currentGroup) {
          currentGroup.applications.push(curr);
          return acc;
        }

        return [
          ...acc,
          {
            applicationCategoryId:
              currentApplicationMapping.applicationCategoryId,
            applicationCategoryTypeId:
              currentApplicationMapping.applicationCategoryTypeId,
            applicationCategory: tmpAssets.applicationCategories.filter(
              (c: any) =>
                c.applicationCategoryId ===
                currentApplicationMapping.applicationCategoryId
            )[0],
            applications: [curr],
          },
        ];
      }, []);

    return tmpCategoryGroups;
  };

  useEffect(() => {
    dispatch(getEsgAssets());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let assets = commons.esgAssets;

    if (
      assets.applicationCategories &&
      assets.applicationMappings &&
      assets.applicationCategories.length > 0 &&
      assets.applicationMappings.length > 0 &&
      assets.applicationCategories[0].applicationCategoryId !== 0
    ) {
      let tempApplications: any = {};

      assets.applications.forEach((app) => {
        tempApplications[`${app.applicationId}`] = app;
      });

      let appCategory = assets.applicationCategories
        .sort(sortByDisplayOrder)
        .filter(
          (c) =>
            c.applicationCategoryId === APPLICATION_CATEGORY.PORTAL_HOMEPAGE
        );

      appCategory.map((category: any) => {
        category.applications = [];
        assets.applicationMappings.forEach((element) => {
          if (
            category.applicationCategoryId === element.applicationCategoryId
          ) {
            if (tempApplications[element.applicationId]) {
              return category["applications"].push(
                tempApplications[element.applicationId]
              );
            }
          }
        });
        return category;
      });

      setEsgAssets(appCategory);
      setTabs(appCategory);
      setCategory(appCategory[0].applicationCategoryId);
      setNavBarAssets(getNavBarAssets(assets));
    } else {
      setEsgAssets(assets.applications);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  //added check to ensure currentUser's email is loaded and is a part of dependency array to prevent null user profile from being tracked
  useEffect(() => {
    if (!assetsLoaded && isAuthenticated && commonState.currentUser.email) {
      setAssetsLoaded(true);
      // Adobe Analytics
      const adobeAnalytics = document.createElement("script");
      adobeAnalytics.src = `${window.ESG?.ENV?.ADOBE_URL}`;
      adobeAnalytics.async = true;
      document.head.appendChild(adobeAnalytics);

      const adobeSatellite = document.createElement("div");
      adobeSatellite.innerHTML = `<script type="text/javascript">_satellite.pageBottom();</script>`;
      document.body.appendChild(adobeSatellite);
      dispatch(esgPortalAccesedTrack(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, commonState.currentUser.email]);

  useEffect(() => {
    const userInitials =
      commons.currentUser.firstName?.charAt(0) +
      commons.currentUser.lastName?.charAt(0);
    setUserInitials(userInitials);
    const userName =
      commons.currentUser.firstName + " " + commons.currentUser.lastName;
    setUserName(userName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    let assets: any = [];

    //commented out code required for searching and all tab since search bar is removed for now

    // if (all && searchInput.trim().length === 0) {
    //   assets = commons.esgAssets;
    //   let esgInsightsApps = commons.esgAssets[1];
    //   let esgServiceApps = commons.esgAssets[0];
    //   console.log("now check this");
    //   console.log(esgInsightsApps);
    //   console.log(esgServiceApps);

    // } else if (all && searchInput.trim().length > 0) {
    //   let filterAssets = commons.esgAssets.filter((asset) =>
    //     asset.applications.some((app) =>
    //       app.applicationName
    //         .toLocaleLowerCase()
    //         .includes(searchInput.toLocaleLowerCase())
    //     )
    //   );

    //   assets = filterAssets.map((element) => {
    //     return {
    //       ...element,
    //       applications: element.applications.filter((subElement: any) =>
    //         subElement.applicationName
    //           .toLocaleLowerCase()
    //           .includes(searchInput.toLocaleLowerCase())
    //       ),
    //     };
    //   });
    // }

    // removing this code - not usable now
    if (category && searchInput.trim().length === 0) {
      assets = commons.esgAssets.applicationCategories.filter(
        (asset) => asset.applicationCategoryId === category
      );
    } else if (category && searchInput.trim().length > 0) {
      let filterCategory = commons.esgAssets.applicationCategories.filter(
        (asset) => asset.applicationCategoryId === category
      );
      let filterAssets = filterCategory.map((element) => {
        return {
          ...element,
          applications: element.applications!.filter((subElement: any) =>
            subElement.applicationName
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase())
          ),
        };
      });
      assets = filterAssets.filter(
        (category) => category.applications.length > 0
      );
    }

    if (category) {
      assets = commons.esgAssets.applicationCategories.filter((asset) => {
        return asset.applicationCategoryId === category;
      });

      setEsgAssets(assets);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput, category]);

  useEffect(() => {
    const fixedMargin = 6;

    setMarginTop(
      commonState.cachedActiveMaintenanceMessages.length * 2.5 + fixedMargin
    );
  }, [commonState.cachedActiveMaintenanceMessages]);

  const sortByDisplayOrder = (a: any, b: any) => {
    return a.displayOrder - b.displayOrder;
  };

  const getTabItems = (item: any) => {
    return (
      <span
        className={
          category === item.applicationCategoryId
            ? `${BLOCK}__tab-active`
            : `${BLOCK}__tab`
        }
        key={item.applicationCategoryId}
        onClick={() => {
          setCategory(item.applicationCategoryId);
        }}
      >
        {item.applicationCategoryName}
      </span>
    );
  };

  useOutsideAlerter(supportMenuRef, () => setSupportMenu(false));
  useOutsideAlerter(logoutMenuRef, () => setlogoutMenu(false));
  useOutsideAlerter(hamburgerMenuRef, () => setShowHeaderMenu(false));
  const logoutHandler = () => {
    history.push(logout);
  };

  const getMenuAppItem = (
    item: any,
    index: number,
    isLastItem: boolean,
    isLastCategory: boolean
  ) => {
    return (
      <div
        key={`${index}_item`}
        className={classNames(`${BLOCK}__menu-item`, {
          [`${BLOCK}__menu-item--shadow`]: isLastItem && !isLastCategory,
        })}
        onClick={() => {
          dispatch(
            esgPortalAppAccesedTrack({
              applicationName: item.applicationName,
            })
          );
          window.open(item.applicationUrl, "_blank");
        }}
      >
        <div className={`${BLOCK}__menu-item-icon`}>
          <span>
            {Parser(item.applicationIcon ? item.applicationIcon : "")}
          </span>
        </div>
        <div className={`${BLOCK}__menu-item-content`}>
          <span className={classNames(`${BLOCK}__menu-item-label`)}>
            {item.applicationName}
          </span>
          {item.applicationSubText && (
            <span className={`${BLOCK}__menu-item-subtext`}>
              {item.applicationSubText}
            </span>
          )}
        </div>
      </div>
    );
  };

  const getMenuCategoryItem = (category: any, i: number) => {
    return (
      <>
        <span
          key={`${i}_categ`}
          className={classNames(
            `${BLOCK}__menu-item ${BLOCK}__menu-item--shadow ${BLOCK}__menu-item-label--bold ${BLOCK}__menu-item-label--spacing`
          )}
        >
          <span className={`${BLOCK}__menu-item-icon`}>
            {Parser(
              category.applicationCategory.applicationCategoryIcon
                ? category.applicationCategory.applicationCategoryIcon
                : ""
            )}
          </span>
          {category.applicationCategory.applicationCategoryName}
        </span>
        {category.applications.map((a: any, ind: number) =>
          getMenuAppItem(
            a,
            ind,
            category.applications.length - 1 === ind,
            navBarAssets.length - 1 === i
          )
        )}
      </>
    );
  };
  return (
    <>
      <div className={BLOCK} data-test="home-page-component">
        <div className={`${BLOCK}__header`}>
          <div className={`${BLOCK}__app`}>
            <Icon name="deloitte" height={16} className="icon" />
            <Icon name="omnia" height={16} className="icon" />
          </div>
          <div className={`${BLOCK}__help-menu`} data-test="help-menu">
            <div ref={supportMenuRef}>
              <span
                className={`${BLOCK}__menu-icon`}
                data-test="help-menu"
                onClick={() => setSupportMenu(!supportMenu)}
              >
                <Icon
                  name="question-mark"
                  height={16}
                  width={16}
                  className="icon"
                />
              </span>
              {supportMenu ? (
                <div
                  className={`${BLOCK}__support-menu-container`}
                  data-test="help-menu-container"
                >
                  <div className={`${BLOCK}__request-support`}>
                    <span>
                      <FormattedMessage id="request.support" />
                    </span>
                  </div>
                  <div className={`${BLOCK}__request-support-link`}>
                    <span
                      onClick={() => {
                        window.open(
                          "https://deloitteus.service-now.com/sp?id=sp_kb_article&sys_id=c99cd2d0dbbefc1c9275a478139619a7",
                          "_blank"
                        );
                        setSupportMenu(false);
                      }}
                    >
                      <FormattedMessage id="request.support.link" />
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div ref={logoutMenuRef}>
              {userInitials ? (
                <span
                  onClick={() => setlogoutMenu(!logoutMenu)}
                  className={`${BLOCK}__user-section`}
                  data-testid="user-section"
                >
                  {userInitials}
                </span>
              ) : (
                ""
              )}

              {logoutMenu ? (
                <div
                  className={`${BLOCK}__menu-container`}
                  data-testid="logout-menu"
                >
                  <div className={`${BLOCK}__user-details`}>
                    <div className={`${BLOCK}__user-avtar`}>
                      <span> {userInitials}</span>
                    </div>
                    <div className={`${BLOCK}__user-name`}>
                      <span>{userName}</span>
                    </div>
                  </div>
                  <div className={`${BLOCK}__logout`} onClick={logoutHandler}>
                    <div>
                      <span>
                        <Icon
                          name="exit"
                          height={16}
                          width={16}
                          className="icon"
                        />
                      </span>
                    </div>
                    <div>
                      <span className={`${BLOCK}__logout-user`}>
                        {" "}
                        <FormattedMessage id="logout" />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={`${BLOCK}__app-name`} data-test="esg-portal">
          {/* <FormattedMessage id="esg.portal" />{" "} */}
          <div className={`${BLOCK}__menu`}>
            <div
              onClick={() => {
                setShowHeaderMenu(!showHeaderMenu);
              }}
            >
              <Icon name="hamburger-icon" width={24} height={24} />
            </div>
          </div>

          {showHeaderMenu && (
            <div className={`${BLOCK}__menu-panel`} ref={hamburgerMenuRef}>
              <div className={`${BLOCK}__menu-close-wrapper`}>
                <button
                  className={`${BLOCK}__menu-close-btn`}
                  onClick={() => setShowHeaderMenu(false)}
                >
                  <Icon name="cross" width={24} height={24} />
                </button>
              </div>
              {navBarAssets.map((item: any, index: number) =>
                getMenuCategoryItem(item, index)
              )}
            </div>
          )}
        </div>
        <MaintenanceMessageList />
        <div
          className={`${BLOCK}__search-section`}
          style={{ marginTop: `${marginTop}rem` }}
        >
          <div className={`${BLOCK}__search-category`}>
            {/* <span
              className={all ? `${BLOCK}__all-active` : `${BLOCK}__all`}
              data-test="all-tab"
              data-testid="all-tab"
              onClick={() => {
                setAll(true);
                setCategory("");
              }}
            >
              {" "}
              <FormattedMessage id="all" />
            </span> */}

            {/* FOR CATEGORY  */}
            {/* {!commons.loadEsgAssets &&
              commons.esgAssets.applicationCategories &&
              commons.esgAssets.applicationMappings &&
              commons.esgAssets.applicationCategories.length > 0 &&
              commons.esgAssets.applicationMappings.length > 0 &&
              tabs?.map((item: any, index: any) => getTabItems(item))} */}
          </div>
          {/* <div className={`${BLOCK}__search-assets`}>
            <Icon name="search" height={25} width={25} className="icon" />
            <input
              type="text"
              value={searchInput}
              data-test="search-input"
              data-testid="search-input"
              className={`${BLOCK}__search-input`}
              placeholder="Search for App and Services"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput.trim().length > 0 && (
              <span
                className={`${BLOCK}__clear-search`}
                onClick={() => setSearchInput("")}
              >
                <Icon name="cross" height={25} width={25} className="icon" />
              </span>
            )}
          </div> */}
        </div>
        <div>
          {commons.loadEsgAssets && (
            <div className={`${BLOCK}__empty-container`}>
              <Icon
                name={"loading"}
                width={50}
                height={50}
                className="loading-icon"
                data-test="loading-icon"
              />
            </div>
          )}
          {commons.esgAssets.applicationCategories &&
          commons.esgAssets.applicationMappings &&
          commons.esgAssets.applicationMappings.length > 0 &&
          commons.esgAssets.applicationCategories.length > 0 ? (
            !commons.loadEsgAssets &&
            esgAssets?.sort(sortByDisplayOrder).map((item: any, index: any) => (
              <div
                key={
                  item.applicationCategoryId
                    ? `${index}_${item.applicationCategoryId}__body`
                    : `${index}_${item.applicationId}__body`
                }
                data-test="esg-assets"
              >
                <>
                  {item.applications && !item.applicationCategoryName && (
                    <span
                      className={`${BLOCK}__category-name`}
                      data-test="category-name"
                    >
                      {item.applicationCategoryName}
                    </span>
                  )}
                  <div className={`${BLOCK}__category`}>
                    {item.applications
                      ?.sort(sortByDisplayOrder)
                      ?.map((app: any, index: any) => (
                        <div
                          className={`${BLOCK}__tiles`}
                          key={`${index}_${item.applicationCategoryId}__tiles`}
                        >
                          <div className={`${BLOCK}__tiles-icon`}>
                            <div>
                              <span>{Parser(app.applicationIcon)}</span>
                            </div>
                            <div className={`${BLOCK}__launch-button`}>
                              {app.applicationUrl && (
                                <span
                                  className={`${BLOCK}__launch-icon`}
                                  onClick={() => {
                                    dispatch(
                                      esgPortalAppAccesedTrack({
                                        applicationName: app.applicationName,
                                      })
                                    );
                                    window.open(app.applicationUrl, "_blank");
                                  }}
                                >
                                  <FormattedMessage id="launch" />
                                  <Icon
                                    name="External-Link-blue"
                                    height={12}
                                    width={12}
                                    className="icon"
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                          <div className={`${BLOCK}__tiles-title`}>
                            {app.applicationName}
                          </div>
                          <div className={`${BLOCK}__tiles-info`}>
                            {app.applicationDescription}
                          </div>
                          <div className={`${BLOCK}__tiles-chip`}>
                            <span
                              className={
                                app.userAccessTypeId === 1
                                  ? `${BLOCK}__tiles-chip-global`
                                  : `${BLOCK}__tiles-chip-us`
                              }
                            >
                              {app.userAccessTypeId === 1 ? (
                                <FormattedMessage id="global" />
                              ) : (
                                <FormattedMessage id="us" />
                              )}
                            </span>
                            {app.isVPNRequire && (
                              <span className={`${BLOCK}__vpnButton`}>
                                <FormattedMessage id="vpn.required" />
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              </div>
            ))
          ) : (
            <div className={`${BLOCK}__category`}>
              {!commons.loadEsgAssets &&
                esgAssets
                  ?.sort(sortByDisplayOrder)
                  .map((app: any, index: any) => (
                    <div
                      className={`${BLOCK}__tiles`}
                      key={`${index}_${app.applicationId}__tiles`}
                    >
                      <div className={`${BLOCK}__tiles-icon`}>
                        <div>
                          <span>{Parser(app.applicationIcon)}</span>
                        </div>
                        <div className={`${BLOCK}__launch-button`}>
                          {app.applicationUrl && (
                            <span
                              className={`${BLOCK}__launch-icon`}
                              onClick={() => {
                                dispatch(
                                  esgPortalAppAccesedTrack({
                                    applicationName: app.applicationName,
                                  })
                                );
                                window.open(app.applicationUrl, "_blank");
                              }}
                            >
                              <FormattedMessage id="launch" />
                              <Icon
                                name="External-Link-blue"
                                height={12}
                                width={12}
                                className="icon"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={`${BLOCK}__tiles-title`}>
                        {app.applicationName}
                      </div>
                      <div className={`${BLOCK}__tiles-info`}>
                        {app.applicationDescription}
                      </div>
                      <div className={`${BLOCK}__tiles-chip`}>
                        <span
                          className={
                            app.userAccessTypeId === 1
                              ? `${BLOCK}__tiles-chip-global`
                              : `${BLOCK}__tiles-chip-us`
                          }
                        >
                          {app.userAccessTypeId === 1 ? (
                            <FormattedMessage id="global" />
                          ) : (
                            <FormattedMessage id="us" />
                          )}
                        </span>
                        {app.isVPNRequire && (
                          <span className={`${BLOCK}__vpnButton`}>
                            <FormattedMessage id="vpn.required" />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          )}
        </div>
        {!commons.loadEsgAssets && esgAssets?.length === 0 && (
          <div className={`${BLOCK}__empty`} data-test="empty-esg-assets">
            <div className={`${BLOCK}__img`}>
              <img className={""} src={SearchNotFound} alt={SearchNotFound} />
            </div>
            <div className={`${BLOCK}__emptytext`}>
              {searchInput.length > 0 ? (
                <>
                  <FormattedMessage
                    id="no.result"
                    values={{ input: searchInput }}
                  />
                  <span>{`"${searchInput}".`}</span>
                </>
              ) : (
                <FormattedMessage
                  id="no.result.found"
                  values={{ input: searchInput }}
                />
              )}
            </div>
            <div className={`${BLOCK}__emptyredirect`}>
              <FormattedMessage id="homepage.no.result.message" />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
