import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "../shared/icon/icon";
import { useState, useEffect, useRef } from "react";
import { useOutsideAlerter } from "utils/functions";
import history from "../../utils/history";
import { useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { logout } from "utils/constants";

interface Map {
  [key: string]: string;
}

const PUBLIC_URL_ENV = process.env.PUBLIC_URL;

const CookieNotice = () => {
  const BLOCK = "HomePage";
  const commons = useSelector((state: RootStore) => state.commons);
  const [logoutMenu, setlogoutMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);
  const supportMenuRef = useRef<HTMLDivElement>(null);
  const logoutMenuRef = useRef<HTMLDivElement>(null);
  const [userInitials, setUserInitials] = useState("");
  const [userName, setUserName] = useState("");
  const currentUser = commons.currentUser;

  useEffect(() => {
    const userInitials =
      commons.currentUser.firstName?.charAt(0) +
      commons.currentUser.lastName?.charAt(0);
    setUserInitials(userInitials);
    const userName =
      commons.currentUser.firstName + " " + commons.currentUser.lastName;
    setUserName(userName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useOutsideAlerter(supportMenuRef, () => setSupportMenu(false));
  useOutsideAlerter(logoutMenuRef, () => setlogoutMenu(false));

  const logoutHandler = () => {
    history.push(logout);
  };

  const BLOCK_2 = "cookie-notice";
  const [cookieNoticeData, setCookieNoticeData] = useState<any>({});
  const [cookieNoticeMetadata, setCookieNoticeMetadata] = useState<any>({});
  const HIDDEN_PREFIX = "hidden.";
  const fetchCookieData = async () => {
    try {
      await fetch("cookie.notice.metadata.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonRes) => {
          setCookieNoticeMetadata(jsonRes);
        });
      await fetch("cookie.notice.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((jsonRes) => {
          setCookieNoticeData(jsonRes);
        });
    } catch (e) {}
  };

  useEffect(() => {
    fetchCookieData();
  }, []);

  const COOKIE_VARIABLES: Map = {
    PUBLIC_URL_LINK: PUBLIC_URL_ENV,
  };

  const getContentText = (content: any, variables: any) => {
    if (variables) {
      content = content.map((val: string) => {
        if (variables.hasOwnProperty(val)) {
          if (val in cookieNoticeData) {
            return getHtmlElement(val);
          } else if (COOKIE_VARIABLES.hasOwnProperty(val)) {
            return COOKIE_VARIABLES[`${val}`];
          }
          return variables[val];
        }

        return val;
      });
    }

    return content.filter((c: any) => c !== "");
  };

  const getHtmlProps = (properties: any = null) => {
    let props = {};
    if (properties) {
      for (const [key, value] of Object.entries(properties)) {
        if (
          typeof value !== "object" &&
          value !== null &&
          !Array.isArray(value)
        ) {
          let stringVal = (value as string).split(/\{(.*?)\}/i);
          let newValue = getContentText(stringVal, properties.variables);
          props = { ...props, [key]: newValue };
        }
      }
    }
    return props;
  };

  const getHtmlElement = (jsonKey: string) => {
    let key = jsonKey in cookieNoticeData ? jsonKey : "default";
    const metadata =
      jsonKey in cookieNoticeMetadata
        ? cookieNoticeMetadata[`${jsonKey}`]
        : null;
    let content = (cookieNoticeData as any)[key].split(/\{(.*?)\}/i);
    key = key.replace(HIDDEN_PREFIX, "");
    const Tag = key.split(".")[0] as keyof JSX.IntrinsicElements;
    content = getContentText(
      content,
      metadata && metadata.hasOwnProperty("variables")
        ? metadata.variables
        : null
    );
    let children =
      metadata && metadata.hasOwnProperty("children")
        ? metadata.children
        : null;

    return (
      <Tag {...getHtmlProps(metadata)}>
        {content}
        {children && children.map((child: string) => getHtmlElement(child))}
      </Tag>
    );
  };

  const getHtml = () => {
    let currentHtml: any = [];
    {
      Object.keys(cookieNoticeData).forEach((key, index) => {
        if (!key.startsWith(HIDDEN_PREFIX)) {
          currentHtml.push(getHtmlElement(key));
        }
      });
    }
    return currentHtml.length === 0 ? null : currentHtml;
  };

  return (
    <>
      <div className={`${BLOCK}__header`}>
        <div className={`${BLOCK}__app`}>
          <Icon name="deloitte" height={16} className="icon" />
          <Icon name="omnia" height={16} className="icon" />
        </div>
        <div className={`${BLOCK}__help-menu`} data-test="help-menu">
          <div ref={supportMenuRef}>
            <span
              className={`${BLOCK}__menu-icon`}
              data-test="help-menu"
              onClick={() => setSupportMenu(!supportMenu)}
            >
              <Icon
                name="question-mark"
                height={16}
                width={16}
                className="icon"
              />
            </span>
            {supportMenu ? (
              <div
                className={`${BLOCK}__support-menu-container`}
                data-test="help-menu-container"
              >
                <div className={`${BLOCK}__request-support`}>
                  <span>
                    <FormattedMessage id="request.support" />
                  </span>
                </div>
                <div className={`${BLOCK}__request-support-link`}>
                  <span
                    onClick={() => {
                      window.open(
                        "https://deloitteus.service-now.com/sp?id=sp_kb_article&sys_id=c99cd2d0dbbefc1c9275a478139619a7",
                        "_blank"
                      );
                      setSupportMenu(false);
                    }}
                  >
                    <FormattedMessage id="request.support.link" />
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div ref={logoutMenuRef}>
            {userInitials ? (
              <span
                onClick={() => setlogoutMenu(!logoutMenu)}
                className={`${BLOCK}__user-section`}
                data-testid="user-section"
              >
                {userInitials}
              </span>
            ) : (
              ""
            )}

            {logoutMenu ? (
              <div
                className={`${BLOCK}__menu-container`}
                data-testid="logout-menu"
              >
                <div className={`${BLOCK}__user-details`}>
                  <div className={`${BLOCK}__user-avtar`}>
                    <span> {userInitials}</span>
                  </div>
                  <div className={`${BLOCK}__user-name`}>
                    <span>{userName}</span>
                  </div>
                </div>
                <div className={`${BLOCK}__logout`} onClick={logoutHandler}>
                  <div>
                    <span>
                      <Icon
                        name="exit"
                        height={16}
                        width={16}
                        className="icon"
                      />
                    </span>
                  </div>
                  <div>
                    <span className={`${BLOCK}__logout-user`}>
                      {" "}
                      <FormattedMessage id="logout" />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className={`${BLOCK}__app-name`} data-test="esg-portal"></div>
      {Object.keys(cookieNoticeData).length > 0 && (
        <div className={`${BLOCK_2}__content`}>{getHtml()}</div>
      )}
    </>
  );
};

export default CookieNotice;
