import { ACTIONS as commonsActions } from "services/commons.service";

const TRACKING_ACTIONS: any = {
  [commonsActions.ESG_PORTAL_ACCESSED_TRACK]: {
    type: commonsActions.ESG_PORTAL_ACCESSED_TRACK,
    dcr: "page_load",
    action: "ESG Portal - Home",
  },
  [commonsActions.ESG_PORTAL_APP_ACCESSED_TRACK]: {
    type: commonsActions.ESG_PORTAL_APP_ACCESSED_TRACK,
    dcr: "page_load",
    action: "ESG Portal - App accessed",
  },
};

export default TRACKING_ACTIONS;
