type props = {
  className: string;
  disable: boolean;
  linkBody: JSX.Element;
  target: string;
  documentUrl: string;
};

const DocumentLink = ({
  className,
  disable,
  linkBody,
  target,
  documentUrl,
}: props) => {
  const getDocumentHandler = () => {
    let newWindows: any;
    if (target === "_blank") {
      newWindows = window.open("/loading");
    }
    /*istanbul ignore next*/
    if (target === "_blank" && newWindows) {
      newWindows.location = documentUrl;
    }
  };

  return (
    <button
      onClick={
        disable
          ? (e) => {
              e.preventDefault();
            }
          : getDocumentHandler
      }
      className={className}
    >
      {linkBody}
    </button>
  );
};

export default DocumentLink;
