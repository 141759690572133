export const cookiesToIgnore = ["OptanonConsent", "OptanonAlertBoxClosed"];

export const MENU_ITEMS = {
  search: {
    icon: "search",
    path: "/search",
  },
  mysearches: {
    icon: "mysearches",
    path: "/mysearches",
  },
  publishedsearches: {
    icon: "published",
    path: "/mysearches/published",
  },
  saved: {
    icon: "saved",
    path: "/shortlist",
  },
  comparisons: {
    icon: "comparisons",
    path: "/comparisons",
  },
  downloads: {
    icon: "downloads",
    path: "/downloads",
  },
};

export enum FILTER_OPTIONS {
  COMPANY = "company",
  CONTENT = "content",
  DOCUMENT_TYPE = "document_type",
  PERIOD = "period",
  INDUSTRY = "industry",
  AUDITOR = "auditor",
  GOVERNING_BOARD = "goberning_board",
}

export const HighlightOptions = {
  padding: 5,
  color: "rgba(255, 230, 127,0.5)",
  baseColor: "rgba(160, 220, 255, 0.6)",
  bookends: "#00A3E0",
};

export const SessionTimeout = {
  CHECK_INTERVAL_MS: 1000,
  IDLE_WAIT_TIME_MS: 1500000,
  WARNING_TIME_MS: 300000,
};

export const companyPagination = {
  pageNumber: 1,
  totalRecordCount: 30,
};

export const NOTE_TYPES = {
  SHORTLIST: 0,
  SHORTLIST_ITEM: 1,
  COMPARISON: 2,
  COMPARISON_ITEM: 3,
};

export const homepage = "/";
export const unauthorizedPath = "/unauthorized";
export const logout = `${
  window.ESG?.ENV?.LOGOUT_URL ? window.ESG?.ENV?.LOGOUT_URL : "/logout"
}`;
export const searchPath = "/search";
export const reportPath = "/report/";
export const comparisonPath = "/comparison";
export const savedSearchesPath = "/mysearches";
export const publishedSearchesPath = "/mysearches/published";
export const dropdownHeight = 340;

export const ESGsortResults = {
  sortBy: "ReportYear",
  sortOrder: "desc",
};

export const SECsortResults = {
  sortBy: "PeriodName",
  sortOrder: "desc",
};

export const SORT_ORDERS = {
  ASC: "asc",
  DESC: "desc",
  NONE: null,
};

export const NOTIFICATION_TYPES = {
  SHARE_SEARCH: 1,
  SHARE_SHORTLIST: 2,
  TO_BE_PURGED_SHARE: 3,
  SHARE_COMPARE: 4,
};

export const NOTIFICATION_STATUS = {
  SHOW: 1,
  CLOSED: 2,
};

export const TOAST_OPTIONS = {
  dismissTime: 5000,
  position: "bottom-center",
};

export const TABLE_ACTIONS = {
  SORT: "sort",
  NEXT_PAGE: "next_page",
  INIT: "init",
};

export const DEFAULT_SHORTLISTS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
  contentText: "",
};

export const DEFAULT_SAVED_COMPARISONS_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "lastUpdatedDate",
  sortOrder: "desc",
};
export const DEFAULT_SAVED_SEARCHES_PAYLOAD = {
  pageNumber: 1,
  pageSize: 30,
  sortBy: "null",
  sortOrder: "null",
};

export const DEFAULT_ESG_EXPORT_PAYLOAD = {
  exportReferenceId: 0, // Respective Id
  exportFileName: "", // Respective Name
  exportType: 1, // 1 - ESG, 2 - SEC, 3 - SHORTLIST
  exportFormat: 1, // 1 - pdf, 2 - xls, 3 - word
};

export const DEFAULT_SEC_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 2,
  exportFormat: 1,
};
export const EXPORT_FORMAT = {
  xls: 0,
  pdf: 1,
};

export const DEFAULT_SHORTLIST_EXPORT_PAYLOAD = {
  exportReferenceId: 0,
  exportFileName: "",
  exportType: 3,
  exportFormat: 1,
  searchCriteria: {
    contentText: "",
  },
};

export const DOWNLOAD_EXPORT_TYPES = {
  NEW: 1,
  IN_PROGRESS: 2,
  READY_TO_DOWNLOAD: 3,
  DONE: 4,
  ERROR: 5,
};

export const DEFAULT_ESG_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 0,
  contentKeywords: "",
  displayOrder: 0,
};

export const DEFAULT_SEC_REMOVE_PAYLOAD = {
  shortlistId: 0,
  esgReport: {
    reportId: null,
  },
  sectionItem: {
    filingId: null,
    sectionId: null,
  },
  shortlistItemType: 1,
  contentKeywords: "",
  displayOrder: 0,
};

export const ScrollOffset = 10;

export const overlay = {
  MAX_SELECTION_OFFSET: 30,
  NEXT_WORD_DELTA: 1,
  BREAK_CHARACTERS: [32],
};

export const shareItemType = {
  search: 1,
  shortlist: 2,
  compare: 3,
};

export const comparisonItemTypes = {
  ESG: 0,
  SEC: 1,
};

export const shortlistItemType = {
  ESG: 0,
  SEC: 1,
};

export const shortlistTableFilter = {
  company: {
    type: "companyName",
    name: "Company",
  },
  title: {
    type: "shortlistItemTitle",
    name: "Title",
  },
  documentType: {
    type: "shortlistItemDocumentType",
    name: "Document Type",
  },
  period: {
    type: "shortlistItemPeriod",
    name: "Period",
  },
};

export const comparisonTableFilter = {
  name: {
    type: "ComparisonName",
    name: "Name",
  },
  description: {
    type: "ComparisonDescription",
    name: "Description",
  },
};

export const publishSearchType = {
  savedSearches: 1,
  publishedSearches: 2,
};

export const comparisonNameLimit = 200;
export const reportNameLimit = 100;

export const APPLICATION_CATEGORY = {
  PORTAL_HOMEPAGE: 1,
};

export const APPLICATION_CATEGORY_TYPE = {
  NAV_BAR: 2,
};

export const APP_SETTING = {
  termOfUse: "TermsOfUse",
};

export const LOCALSTORAGE_VARIABLE = {
  closedActiveMaitenanceMessage: "closed-active-maintenance-message",
};

export const AI_CUSTOM_METRIC_NAME = "ESGPortal-UI-Engagement-Time";

export const AI_CUSTOM_METRIC_PAGE_NAME = "Page Name";

export const AI_COMPONENT_PATH_MAP: { [key: string]: string } = {
  "/": "ESG Portal - Home",
};
