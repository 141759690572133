import { useEffect, useRef } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js"; // Adjust the import path accordingly
import {
  AI_COMPONENT_PATH_MAP,
  AI_CUSTOM_METRIC_NAME,
  AI_CUSTOM_METRIC_PAGE_NAME,
} from "utils/constants";

// Custom hook to track custom metrics
const useAppInsightsCustomMetric = (pathName: string) => {
  const engagementTime = useRef(0);
  const appInsights = useAppInsightsContext();

  const componentName = AI_COMPONENT_PATH_MAP[pathName];

  useEffect(() => {
    const startTime = performance.now();
    // App insights Track page view
    appInsights.trackPageView({ name: componentName, uri: pathName });

    return () => {
      const endTime = performance.now();
      engagementTime.current = (endTime - startTime) / 1000; // Convert to seconds
      // App insights Track engagement time
      const metricData = {
        average: engagementTime.current,
        name: AI_CUSTOM_METRIC_NAME,
        sampleCount: 1,
      };
      const additionalProperties = {
        [AI_CUSTOM_METRIC_PAGE_NAME]: componentName,
      };
      appInsights.trackMetric(metricData, additionalProperties);
    };
  }, [pathName]);

  return appInsights;
};

export default useAppInsightsCustomMetric;
