/** Utils to scrap and clean up data to send to analytics  */
const PAGE = {
  HOMEPAGE: "Home - ESG Portal",
};

/** Object table listing the different pages on ESG Insights to be displayed on Analytics Page */
const LOCATION_METADATA: any = {
  home: {
    pathTemplate: "/",
    pageName: PAGE.HOMEPAGE,
  },
};

/** Convert the pathname to the corresponding page name */
const checkInput = (path: any, hash = "") => {
  let match = null;
  const input = path + hash;
  Object.keys(LOCATION_METADATA).forEach((keyword) => {
    if (input.includes(keyword)) {
      match = LOCATION_METADATA[keyword];
    }
  });

  if (!match && input === "/") {
    match = { pageName: PAGE.HOMEPAGE };
  }

  return match ? match : { pageName: "ESG" };
};

/** Clean up email helper, removed '@deloitte.com' */
export const cleanEmail = (email: string) => {
  if (typeof email === "string") {
    return { email: email.slice(0, -13) };
  }
  return { email: "" };
};

/** Get location page name helper function to be merged into data layer */
export const getLocation = (path: any, obj: { hash: string }) => {
  const copy = obj;
  return Object.assign(copy, checkInput(path, obj.hash));
};
