import FormattedMessage from "components/shared/formatted-message/formatted-message";
import { MouseEventHandler, useEffect } from "react";
import Icon from "components/shared/icon/icon";
import classNames from "classnames";

type Props = {
  show: boolean;
  handleClose?: MouseEventHandler;
  headerParams?: any;
  header: any;
  children: JSX.Element;
  config?: boolean;
};

const Modal = ({
  show,
  handleClose,
  headerParams,
  header,
  children,
  config = true,
}: Props) => {
  const BLOCK = "modalESG";

  useEffect(() => {
    const handleCloseOnEscape = (e: any) =>
      handleClose && (e.charCode || e.keyCode) === 27 ? handleClose(e) : null;

    document.body.addEventListener("keydown", handleCloseOnEscape);
    return () => {
      document.body.removeEventListener("keydown", handleCloseOnEscape);
    };
  }, [handleClose]);

  return (
    <div
      className={classNames(BLOCK, { [`${BLOCK}--show`]: show })}
      onClick={config ? handleClose : () => {}}
      data-test="component-modal-show"
      data-testid="component-modal-show"
    >
      <div
        className={classNames(`${BLOCK}__content`, {
          [`${BLOCK}__content--show`]: show,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`${BLOCK}__header`} data-test="modal-header">
          <h1 className = {`${BLOCK}__header--h1--title`}>
            {headerParams ? (
              <FormattedMessage id={header} values={headerParams} />
            ) : (
              <FormattedMessage id={header} />
            )}
          </h1>
          {handleClose && (
            <button
              className={`${BLOCK}__close`}
              data-test="close-modal"
              data-testid="close-modal"
              onClick={handleClose}
            >
              <Icon name="cross" height={24} />
            </button>
          )}
        </div>
        <div className={`${BLOCK}__body`}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
