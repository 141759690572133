import Tooltip, { TooltipPosition } from "components/shared/tooltip/tooltip";
import Button from "components/shared/button/button";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import Icon from "components/shared/icon/icon";
import React, { useState } from "react";
import { getItem, setItem } from "utils/localStorageFunctions";
import { LOCALSTORAGE_VARIABLE } from "utils/constants";
import { activeMaintenanceMessages } from "services/commons.model";

type props = {
  messageObj: activeMaintenanceMessages;
  setMessageList: Function;
};

const MaintenanceMessage = ({ messageObj, setMessageList }: props) => {
  const BLOCK = `maintenance-message`;
  const [showMessage, setShowMessage] = useState(true);
  return showMessage ? (
    <div className={`${BLOCK}__banner`} key={messageObj.maintenanceMessageId}>
      <div className={`${BLOCK}__banner-msg`}>
        <Icon
          name={"warning-circle-yellow"}
          width={24}
          height={24}
          className=""
        ></Icon>
        <span className={`${BLOCK}__banner-msg-text`}>
          {messageObj.maintenanceMessageText}
        </span>
      </div>
      <div className={`${BLOCK}__close`}>
        <Button
          className=""
          data-testid="close-compare"
          onClick={() => {
            const closedActiveMessages = getItem<number[]>(
              LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage
            );
            setShowMessage(false);
            setMessageList((prevList: any) =>
              prevList.filter(
                (prevMessageObj: any) =>
                  prevMessageObj.maintenanceMessageId !==
                  messageObj.maintenanceMessageId
              )
            );
            if (closedActiveMessages && closedActiveMessages.length > 0) {
              const updatedArray = Array.from(
                new Set([
                  ...closedActiveMessages,
                  messageObj.maintenanceMessageId,
                ])
              );
              setItem(
                LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage,
                updatedArray
              );
            } else {
              setItem(LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage, [
                messageObj?.maintenanceMessageId,
              ]);
            }
          }}
          iconName="cross"
        />
        <Tooltip position={TooltipPosition.left}>
          <FormattedMessage id="comparison.close.tooltip" />
        </Tooltip>
      </div>
    </div>
  ) : null;
};

export default MaintenanceMessage;
