export class BaseModel {
  protected state;

  constructor(state: any) {
    this.state = state;
  }

  initialState() {
    return this.state;
  }

  // Return a new object, with the new props merged in
  merge(mergeProps: any) {
    this.state = {
      ...this.state,
      ...mergeProps,
    };
    return this.state;
  }
}

export function createAction(type: string) {
  return function (payload?: any) {
    return {
      type: type,
      payload: payload,
    };
  };
}

export interface errorModel {
  code: number;
  message: string;
}
