import { Dispatch } from "redux";
import { createAction } from "utils/redux.utils";
import { CommonsModel, CommonsState, ToastMessage } from "./commons.model";
import * as api from "./commons.api";

/*
 * Actions
 */

export const ACTIONS = {
  COMMONS_ADD_TOAST_MESSAGE: "ESG/UI/COMMONS_ADD_TOAST_MESSAGE",
  COMMONS_REMOVE_TOAST_MESSAGE: "ESG/UI/COMMONS_REMOVE_TOAST_MESSAGE",
  COMMONS_SHOW_TOOLTIP: "ESG/UI/COMMONS_SHOW_TOOLTIP",
  COMMONS_LOAD_CURRENT_USER: "ESG/UI/COMMONS_LOAD_CURRENT_USER",
  COMMONS_LOAD_ESG_ASSETS: "ESG/UI/COMMONS_LOAD_ESG_ASSETS",
  ESG_ASSETS_LOADING: "ESG/UI/ESG_ASSETS_LOADING",
  ESG_PORTAL_APP_ACCESSED_TRACK: "ESG/UI/ESG_PORTAL_APP_ACCESSED_TRACK",
  ESG_PORTAL_ACCESSED_TRACK: "ESG/UI/ESG_PORTAL_ACCESSED_TRACK",
  COMMONS_SHOW_TERMS_OF_USE_FOOTER: "ESG/UI/COMMONS_SHOW_TERMS_OF_USE_FOOTER",
  SET_ACTIVE_MAINTENANCE_MESSAGES: "ESG/UI/SET_ACTIVE_MAINTENANCE_MESSAGES",
  SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES:
    "ESG/UI/SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES",
};

export const esgPortalAppAccesedTrack = createAction(
  ACTIONS.ESG_PORTAL_APP_ACCESSED_TRACK
);

export const esgPortalAccesedTrack = createAction(
  ACTIONS.ESG_PORTAL_ACCESSED_TRACK
);

export const showTermOfUserFooter = createAction(
  ACTIONS.COMMONS_SHOW_TERMS_OF_USE_FOOTER
);

export const commonsAddToastMessage = createAction(
  ACTIONS.COMMONS_ADD_TOAST_MESSAGE
);
export const commonsRemoveToastMessage = createAction(
  ACTIONS.COMMONS_REMOVE_TOAST_MESSAGE
);

export const settingActiveMaintenanceMessages = createAction(
  ACTIONS.SET_ACTIVE_MAINTENANCE_MESSAGES
);

export const settingCachedActiveMaintenanceMessages = createAction(
  ACTIONS.SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES
);

export const commonsShowTooltip = createAction(ACTIONS.COMMONS_SHOW_TOOLTIP);
export const commonsLoadCurrentUser = createAction(
  ACTIONS.COMMONS_LOAD_CURRENT_USER
);

export const addToastMessage =
  (toastMessage: ToastMessage) => (dispatch: Dispatch<any>) => {
    dispatch(commonsAddToastMessage(toastMessage));
  };

export const removeToastMessage =
  (toasts: ToastMessage[]) => (dispatch: Dispatch<any>) => {
    dispatch(commonsRemoveToastMessage(toasts));
  };

export const esgAssetsLoading = createAction(ACTIONS.ESG_ASSETS_LOADING);
export const loadEsgAssets = createAction(ACTIONS.COMMONS_LOAD_ESG_ASSETS);

export const showTooltip = (toolTip: any) => (dispatch: Dispatch<any>) => {
  dispatch(commonsShowTooltip(toolTip));
};

export const loadCurrentUser = () => async (dispatch: Dispatch<any>) => {
  const currentUser = await api.getCurrentUser();
  window.localStorage.setItem("userID", currentUser.email);
  dispatch(commonsLoadCurrentUser(currentUser));
};

export const loadFooterAppSetting =
  (appSetting: string) => async (dispatch: Dispatch<any>) => {
    const appSettingValue = await api.getAppSetting(appSetting);
    dispatch(showTermOfUserFooter(appSettingValue));
  };

export const loadCookieBanner = () => async (dispatch: Dispatch<any>) => {
  var scriptData = window.ESG?.ENV?.GEOS.find(
    (geo: { GEO_CODE: string }) => geo.GEO_CODE && geo.GEO_CODE !== "NONE"
  );
  const script = document.createElement("script");
  script.src = scriptData.ONETRUST_URL;
  script.type = "text/javascript";
  script.dataset.domainScript = scriptData.ONETRUST_DOMAIN;
  document.body.appendChild(script);
};

export const getEsgAssets = () => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(esgAssetsLoading(true));
    const result = await api.getEsgAssets();
    dispatch(loadEsgAssets(result));
    dispatch(esgAssetsLoading(false));
  } catch (e) {
    dispatch(esgAssetsLoading(false));
  }
};

export const setActiveMaintenanceMessages =
  () => async (dispatch: Dispatch<any>) => {
    try {
      const res = await api.getMaintenanceMessage();
      dispatch(settingActiveMaintenanceMessages(res.data));
    } catch (e) {}
  };
/*
 * Reducer
 */

const commonsModel = new CommonsModel();

const commonsReducer = (
  state: CommonsState = commonsModel.initialState(),
  action: any
): CommonsState => {
  switch (action.type) {
    case ACTIONS.COMMONS_ADD_TOAST_MESSAGE:
      return commonsModel.addToastMessage(action.payload);
    case ACTIONS.COMMONS_REMOVE_TOAST_MESSAGE:
      return commonsModel.removeToastMessage(action.payload);
    case ACTIONS.COMMONS_SHOW_TOOLTIP:
      return commonsModel.showToolTip(action.payload);
    case ACTIONS.COMMONS_LOAD_CURRENT_USER:
      return commonsModel.loadCurrentUser(action.payload);
    case ACTIONS.COMMONS_LOAD_ESG_ASSETS:
      return commonsModel.setEsgAssets(action.payload);
    case ACTIONS.COMMONS_SHOW_TERMS_OF_USE_FOOTER:
      return commonsModel.setShowTermsOfUseFooter(action.payload);
    case ACTIONS.ESG_ASSETS_LOADING:
      return commonsModel.setEsgLoading(action.payload);
    case ACTIONS.SET_ACTIVE_MAINTENANCE_MESSAGES:
      return commonsModel.setActiveMaintenanceMessages(action.payload);
    case ACTIONS.SET_CACHED_ACTIVE_MAINTENANCE_MESSAGES:
      return commonsModel.setCachedActiveMaintenanceMessages(action.payload);

    default:
      return state;
  }
};

export default commonsReducer;
