import io from "socket.io-client";
import { isNumeric } from "./functions";

export const rooms = {
  projects() {
    return `projects`;
  },
  exports(userId: number) {
    return `exports/${userId}`;
  },
  alert(userId: number) {
    return `alerts/${userId}`;
  },
  maintenanceMessageUpdate() {
    return "maintenanceMessages";
  },
};

export class Socket {
  // User access token from Azure AD
  accessToken: string | null = null;
  // If set, initiate a connection once an access token is set
  autoConnect = false;
  // Socket connection is establised
  isConnected = false;
  // Queue of callbacks to run once authenticated and connected
  readyStateCallbacks: any[] = [];
  // Socket object
  socket: any;
  // URL to connect to
  url: string | null = null;
  // Container code
  containerCode: string | null = null;
  // Member firm code
  memberFirmCode: string | null = null;
  // Rooms this socket has joined
  rooms = new Map();

  constructor(config: any) {
    Object.assign(this, config);

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  // Inititate the socket connection
  tryConnect() {
    // Not ready to connect
    if (!this.url || !this.accessToken) {
      return false;
    }

    this.socket = io(this.url, {
      transports: ["websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        },
      },
    });

    this.socket.on("connect", () => {
      this.isConnected = true;
      this.tryRunCallbacks();
    });

    this.socket.on("connect_error", (err: any) => {
      console.log(`connect_error due to ${err.message}`);
    });

    return true;
  }

  tryReconnect() {
    // If already connected disconnect first
    if (this.isConnected) {
      this.disconnect();
    }

    this.tryConnect();
  }

  disconnect() {
    if (!this.socket) {
      return;
    }

    this.socket.close();
    this.rooms = new Map();
  }

  setRegion(url: string, containerCode: string, memberFirmCode: string) {
    this.containerCode = containerCode;
    this.memberFirmCode = memberFirmCode;
    if (this.url === url + "/esg") {
      return;
    }

    this.url = url + "/esg";

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  setAccessToken(accessToken: string) {
    if (this.accessToken === accessToken) {
      return;
    }

    this.accessToken = accessToken;

    if (this.autoConnect) {
      this.tryReconnect();
    }
  }

  on(event: any, callback: any) {
    this.whenReady(() => this.socket.on(event, callback));
  }

  off(event: any, callback: any) {
    this.whenReady(() => this.socket.off(event, callback));
  }

  join(room: any) {
    this.whenReady(() => {
      const refs = this.rooms.get(room);

      // If the room has already been joined, just increment the reference counter
      if (isNumeric(refs)) {
        this.rooms.set(room, refs + 1);
        return;
      }

      // If the room has not been joined, set refs to 1 and join
      this.rooms.set(room, 1);
      this.socket.emit("join", {
        accessToken: this.accessToken,
        //room: `${this.containerCode}/${this.memberFirmCode}/${room}` // TODO: uncomment for globalization
        room,
      });
    });
  }

  leave(room: any) {
    this.whenReady(() => {
      let refs = this.rooms.get(room);

      // Don't leave a room before joining it
      if (!isNumeric(refs)) {
        return;
      }

      // Decrement the reference counter
      if (refs > 0) {
        refs = refs - 1;
        this.rooms.set(room, refs);
      }

      // If there's no more references, leave the room
      if (refs === 0) {
        this.rooms.delete(room);
        this.socket.emit("leave", {
          accessToken: this.accessToken,
          //room: `${this.containerCode}/${this.memberFirmCode}/${room}` // TODO: uncomment for globalization
          room,
        });
      }
    });
  }

  /*
    If not yet connected and authenticated, queue the callback until ready
    Otherwise invoke the callback immediately
  */
  whenReady(callback: any) {
    if (this.isConnected && this.accessToken) {
      callback();
    } else {
      this.readyStateCallbacks.push(callback);
    }
  }

  /*
    After connecting and authenticating, run all pending callbacks
  */
  tryRunCallbacks() {
    if (this.isConnected && this.accessToken) {
      while (this.readyStateCallbacks.length) {
        this.readyStateCallbacks.shift()();
      }
    }
  }
}

const socket = new Socket({
  autoConnect: true,
});

export default socket;
