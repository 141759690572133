import LoaderPage from "components/unauthorized/loaderPage";
import Toast from "components/shared/toast/toast";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { useLocation } from "react-router";
import { APP_SETTING, logout } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import api from "services/api.service";
import { useEffect } from "react";
import {
  addToastMessage,
  loadCurrentUser,
  loadCookieBanner,
  loadFooterAppSetting,
  setActiveMaintenanceMessages,
} from "services/commons.service";
import FormattedMessage from "components/shared/formatted-message/formatted-message";
import SessionTimeoutModal from "components/shared/session-timeout-modal/session-timeout-modal";

type Props = {
  hideHeader?: boolean;
  hideSideMenu?: boolean;
  children: JSX.Element;
};

const Layout = ({ hideHeader, hideSideMenu, children }: Props) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const commons = useSelector((state: RootStore) => state.commons);

  useEffect(() => {
    // Interceptor for catching erros and displaying toast messages
    api.interceptors.response.use(undefined, (err) => {
      const { response } = err;

      if (response && (response.status === 500 || response.status === 400)) {
        dispatch(
          addToastMessage({
            description: <FormattedMessage id="error.default" />,
          })
        );
      }

      throw err;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname !== logout) {
      if (isAuthenticated) {
        dispatch(loadCurrentUser());
        dispatch(loadCookieBanner());
        dispatch(loadFooterAppSetting(APP_SETTING.termOfUse));
        dispatch(setActiveMaintenanceMessages());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, dispatch]);

  return (
    <>
      <AuthenticatedTemplate>
        {window === window.parent && <SessionTimeoutModal />}
        {children}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoaderPage />
      </UnauthenticatedTemplate>
      <Toast toastList={commons.toastMessages} />
    </>
  );
};

export default Layout;
