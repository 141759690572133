import FormattedMessage from "components/shared/formatted-message/formatted-message";
import moment from "moment";
import { useEffect, useState } from "react";

import { LOCALSTORAGE_VARIABLE, SessionTimeout } from "utils/constants";
import Modal from "components/shared/modal/modal";
import { userLogOut } from "services/api.service";
import { logoutCurrentUser } from "services/commons.api";
import { expireCookies } from "utils/functions";
import { removeItem } from "utils/localStorageFunctions";

const { CHECK_INTERVAL_MS, IDLE_WAIT_TIME_MS, WARNING_TIME_MS } =
  SessionTimeout;

/* 
  SessionTimeoutModal
  Handles tracking idle time and timing out the session if it has been inactive for too long
*/
const SessionTimeoutModal = () => {
  const [isWarningShown, setIsWarningShown] = useState(false);
  const [warningCountdownDisplay, setWarningCountdownDisplay] = useState("");
  const [warningCountdownStartTime, setWarningCountdownStartTime] = useState(
    Date.now() + IDLE_WAIT_TIME_MS
  );
  const [userLoggedOut, setUserLoggedOut] = useState(false);

  const BLOCK = "session-overlay";

  // Update the warning countdown and logout if enough time has elapsed
  const updateCountdown = () => {
    // Time left before session timeout
    const warningTimeLeft =
      warningCountdownStartTime + WARNING_TIME_MS - Date.now();

    if (warningTimeLeft <= 0) {
      // Session timeout
      setWarningCountdownDisplay("00:00");
      logout();
    } else {
      // Update the countdown display
      const warningCountdownDisplay = moment()
        .startOf("day")
        .add(warningTimeLeft, "ms")
        .format("mm:ss");
      setWarningCountdownDisplay(warningCountdownDisplay);
    }
  };

  // Check if the session has been idle for too long and show the session timeout warning
  const checkIdleTime = () => {
    if (Date.now() >= warningCountdownStartTime) {
      setIsWarningShown(true);
      updateCountdown();
    }
  };

  // Clear the idle wait check and start it again
  const restartIdleTimeCheck = () => {
    if (isWarningShown) return;
    setWarningCountdownStartTime(Date.now() + IDLE_WAIT_TIME_MS);
  };

  useEffect(() => {
    // Start checking for idle time
    const checkInterval = setInterval(() => {
      isWarningShown ? updateCountdown() : checkIdleTime();
    }, CHECK_INTERVAL_MS);
    bindIdleResetListeners();

    return () => {
      unbindIdleResetListeners();
      clearInterval(checkInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWarningShown,
    warningCountdownStartTime,
    checkIdleTime,
    updateCountdown,
  ]);

  const bindIdleResetListeners = () => {
    // Reset the last active time on the following events which represent user actions
    window.addEventListener("touchstart", restartIdleTimeCheck);
    window.addEventListener("click", restartIdleTimeCheck);
    window.addEventListener("keypress", restartIdleTimeCheck);
    window.addEventListener("scroll", restartIdleTimeCheck, true);
  };

  const unbindIdleResetListeners = () => {
    // Remove all the idle reset event listeners
    window.removeEventListener("touchstart", restartIdleTimeCheck);
    window.removeEventListener("click", restartIdleTimeCheck);
    window.removeEventListener("keypress", restartIdleTimeCheck);
    window.removeEventListener("scroll", restartIdleTimeCheck, true);
  };

  // Hide the warning modal and reset all timers
  const hideWarning = () => {
    setIsWarningShown(false);
    setWarningCountdownStartTime(Date.now() + IDLE_WAIT_TIME_MS);
  };

  const logout = async () => {
    if (userLoggedOut) return;
    setUserLoggedOut(true);
    removeItem(LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage);
    await logoutCurrentUser();
    userLogOut();
    // Expiring the cookies
    expireCookies();
  };

  return (
    <Modal show={isWarningShown} header="overlay.session-timeout.title">
      <div className={BLOCK}>
        <span>
          <FormattedMessage id="overlay.session-timeout.message.first" />
        </span>
        <div className={`${BLOCK}__countdown`}>{warningCountdownDisplay}</div>
        <span>
          <FormattedMessage id="overlay.session-timeout.message.last" />
        </span>
        <div className={`${BLOCK}__options`}>
          <button
            className={`${BLOCK}__logout primary-outline`}
            onClick={() => {
              if (isWarningShown) {
                hideWarning();
                logout();
              }
            }}
          >
            <FormattedMessage id="overlay.session-timeout.logout" />
          </button>
          <button
            className={`${BLOCK}__continue primary`}
            onClick={() => (isWarningShown ? hideWarning() : "")}
          >
            <FormattedMessage id="overlay.session-timeout.continue" />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SessionTimeoutModal;
