import { useEffect, useState } from "react";
import MaintenanceMessage from "./active-maintenance-message";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "services/store.service";
import { getItem, setItem } from "utils/localStorageFunctions";
import { LOCALSTORAGE_VARIABLE } from "utils/constants";
import { activeMaintenanceMessages } from "services/commons.model";
import {
  setActiveMaintenanceMessages,
  settingCachedActiveMaintenanceMessages,
} from "services/commons.service";
import socket, { rooms } from "utils/socket";

const MaintenanceMessageList = () => {
  const BLOCK = `maintenance-message`;
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState<activeMaintenanceMessages[]>(
    []
  );
  const commonState = useSelector((state: RootStore) => state.commons);

  useEffect(() => {
    const closedActiveMessages = getItem<number[]>(
      LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage
    );
    if (!closedActiveMessages || closedActiveMessages.length === 0) {
      setMessageList(commonState.activeMaintenanceMessages);
    }

    if (closedActiveMessages && closedActiveMessages.length > 0) {
      setMessageList(
        commonState.activeMaintenanceMessages.filter(
          (activeMaintenanceMessage: activeMaintenanceMessages) =>
            closedActiveMessages.indexOf(
              activeMaintenanceMessage.maintenanceMessageId
            ) === -1
        )
      );
    }
  }, [commonState.activeMaintenanceMessages]);

  useEffect(() => {
    dispatch(settingCachedActiveMaintenanceMessages(messageList));
  }, [messageList]);

  const UpdateActiveMessagesFromNotification = (payload: any) => {
    dispatch(setActiveMaintenanceMessages());
    const closedActiveMessages = getItem<number[]>(
      LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage
    );

    if (
      payload.data &&
      payload.data.maintenanceMessageId &&
      closedActiveMessages
    ) {
      let tempIndex = closedActiveMessages?.indexOf(
        payload.data.maintenanceMessageId
      );
      if (tempIndex !== -1) {
        closedActiveMessages.splice(tempIndex, 1);
        setItem(
          LOCALSTORAGE_VARIABLE.closedActiveMaitenanceMessage,
          closedActiveMessages
        );
        setMessageList(
          commonState.activeMaintenanceMessages.filter(
            (activeMaintenanceMessage) =>
              closedActiveMessages.indexOf(
                activeMaintenanceMessage.maintenanceMessageId
              ) === -1
          )
        );
      }
    }
  };

  useEffect(() => {
    if (commonState.currentUser.userId) {
      socket.join(rooms.maintenanceMessageUpdate());

      socket.on("maintenanceMessage.status.update", (payload: any) =>
        UpdateActiveMessagesFromNotification(payload)
      );

      return () => {
        socket.leave(rooms.maintenanceMessageUpdate());
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonState.currentUser.userId]);

  return (
    <div className={`${BLOCK}__message-container`}>
      {messageList.map((messageObj: activeMaintenanceMessages) => (
        <MaintenanceMessage
          messageObj={messageObj}
          setMessageList={setMessageList}
          key={`messageObj-${messageObj.maintenanceMessageId}`}
        />
      ))}
    </div>
  );
};

export default MaintenanceMessageList;
