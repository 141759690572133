import { useEffect, useRef, useState } from "react";
import { ReactComponent as ErrorIcon } from "./icons/warning.svg";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string;
  className?: string;
  width?: number;
  height?: number;
}

const Icon = ({ name, className, width, height }: IconProps) => {
  const IconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const importIcon = async (): Promise<void> => {
      try {
        IconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!./icons/${name}.svg`
          )
        ).default;
      } catch (err) {
        IconRef.current = ErrorIcon;
      } finally {
        setLoading(false);
      }
    };
    importIcon();

    return () => {
      setLoading(false);
    };
  }, [name]);

  if (!loading && IconRef.current) {
    const { current: Icon } = IconRef;
    return (
      <div className={className} style={{ width, height }}>
        <Icon width={width} height={height} />
      </div>
    );
  }

  return <div className={className} style={{ width, height }} />;
};

export default Icon;
