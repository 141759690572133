export const setItem = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = <T>(key: string): T | null => {
  const storedItem = localStorage.getItem(key);
  return storedItem ? JSON.parse(storedItem) : null;
};

export const removeItem = (key: string): void => {
  localStorage.removeItem(key);
};

export const pushNumberToArray = (key: string, newNumber: number): void => {
  const currentArray = getItem<number[]>(key) || [];
  const updatedArray = [...currentArray, newNumber];
  setItem(key, updatedArray);
};
